import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "D:/work/BC_Supermarket/bc-supermarket-docs-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="alert alert-info">
    <span className="material-icons">get_app</span> Login to <strong><a href="https://themestore.papathemes.com/bigcommerce" target="_blank">PapaThemes Store</a></strong> to download all these child themes for free when you've already purchased Supermarket theme.
    </div>
    <div className="alert alert-warning">
    <span className="material-icons">warning</span> This free child theme has limited techical support. We'll try to answer your questions about this theme, but we're not committed to answer all and fix all bugs of this theme.
    </div>
    <h1 {...{
      "id": "wholesale-theme"
    }}>{`Wholesale Theme`}</h1>
    <h2 {...{
      "id": "sections-manager"
    }}>{`Sections Manager`}</h2>
    <p>{`You can select the sections displayed on the homepage (maximum 11 sections) in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{` > `}<strong parentName="p">{`Sections`}</strong>{`.`}</p>
    <p><img alt="Homepage Sections Manager" src={require("../img/homepage-wholesale-sections-manager.png")} /></p>
    <p><strong parentName="p">{`List of available section keys:`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`category_carousel`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`new_products`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`bc_banner_home_1`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`featured_products`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`bc_banner_home_2`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`popular_products`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`products_by_category_sorting_tabs`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "wholesale-top-categories-section"
    }}>{`Wholesale Top Categories Section`}</h2>
    <p><img alt="Wholesale Top Categories Section" src={require("../img/sections/wholesale-top-categories-section.png")} /></p>
    <p>{`This block show top categories and its sub-categories sorted by the order set in Product Categories manager.`}</p>
    <p>{`You can configure titles and images of categories in `}<strong parentName="p">{`Product`}</strong>{` > `}<strong parentName="p">{`Product Categories`}</strong>{` > `}<strong parentName="p">{`Category Details`}</strong></p>
    <p><img alt="banner content html source editor" src={require("../img/banner-categories-detail-edit.png")} /></p>
    <h2 {...{
      "id": "product-sections-new--featured--bestselling"
    }}>{`Product Sections (New / Featured / Bestselling)`}</h2>
    <p>{`By default, these sections will not appear on the homepage. However, you can use it.`}</p>
    <p><img alt="Wholesale Product Section" src={require("../img/sections/wholesale-product-section.png")} /></p>
    <p>{`Configure New Products, Featured Products, Bestselling Products showing on the home page in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">{`Number of products to display.`}</li>
    </ul>
    <p><img alt="banner content html source editor" src={require("../img/wholesale-product.png")} /></p>
    <h2 {...{
      "id": "2-banners-on-home-page"
    }}>{`2 Banners on Home Page`}</h2>
    <p><img alt="Wholesale 2 Banners Section" src={require("../img/sections/wholesale-two-banner-sale.png")} /></p>
    <p>{`To show these banners on homepage, go to `}<strong parentName="p">{`Marketing`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{` > click `}<strong parentName="p">{`Create a Banner`}</strong>{` button. In `}<strong parentName="p">{`Banner Content`}</strong>{` editor, click `}<strong parentName="p">{`HTML`}</strong>{` button to open `}<strong parentName="p">{`HTML Source Editor`}</strong>{`, input them sample content below:`}</p>
    <pre><code parentName="pre" {...{}}>{`<div id="emthemesModezBannersBlockHome01" class="emthemesModez-section  emthemesModez-section--banners-2x">
    <div class="emthemesModez-section-inner">
        <div class="emthemesModez-bannersContainer emthemesModez-bannersContainer--half-style1 
            emthemesModez-bannersContainer--half-style1-1">
            <div class="emthemesModez-banner">
                <div class="emthemesModez-banner-container">
                    <a href="#">
                        <img class="emthemesModez-banner-image" src="https://cdn11.bigcommerce.com/s-jda6rmgufj/product_images/uploaded_images/banner-sale1.png?t=1559703922&amp;_ga=2.79681428.105014080.1559554243-1703059024.1550805396" alt="Sample banner 1" />
                    </a>
                </div>
            </div>
            <div class="emthemesModez-banner">
                <div class="emthemesModez-banner-container">
                    <a href="#">
                        <img class="emthemesModez-banner-image" src="https://cdn11.bigcommerce.com/s-jda6rmgufj/product_images/uploaded_images/banner-sale2.png?t=1559703923&amp;_ga=2.135197617.105014080.1559554243-1703059024.1550805396" alt="Sample banner 2" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
`}</code></pre>
    <p><img alt="banner content html source editor" src={require("../img/edit-home1-two-banner.png")} /></p>
    <p>{`Then click `}<strong parentName="p">{`Update`}</strong>{` button to go back the previous screen.`}</p>
    <p>{`Choose `}<strong parentName="p">{`Show on Page`}</strong>{` = `}<strong parentName="p">{`Home Page`}</strong>{`.`}</p>
    <p>{`Choose `}<strong parentName="p">{`Location`}</strong>{` = `}<strong parentName="p">{`Top of Page`}</strong>{`.`}</p>
    <p><img alt="banner content html source editor" src={require("../img/edit-banner-show-on-page-location.png")} /></p>
    <p>{`Click `}<strong parentName="p">{`Save`}</strong>{` button to finish.`}</p>
    <h2 {...{
      "id": "fullwidth-banner-on-home-page"
    }}>{`Fullwidth Banner on Home Page`}</h2>
    <p><img alt="Wholesale Product Section" src={require("../img/sections/wholesale-full-width-banner.png")} /></p>
    <p>{`To show this banner on Home Page, Go to `}<strong parentName="p">{`Marketing`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{` > click `}<strong parentName="p">{`Create a Banner`}</strong>{` button. In `}<strong parentName="p">{`Banner Content`}</strong>{` editor, click `}<strong parentName="p">{`HTML`}</strong>{` button to open `}<strong parentName="p">{`HTML Source Editor`}</strong>{`, input the sample content below:`}</p>
    <pre><code parentName="pre" {...{}}>{`<div id="emthemesModezBannersBlockHome02" class="emthemesModez-section  emthemesModez-section--banners-one">
    <div class="emthemesModez-section-inner">
        <div class="emthemesModez-bannersContainer emthemesModez-bannersContainer--one emthemesModez-bannersContainer--one-1">
            <div class="emthemesModez-banner">
                <div class="emthemesModez-banner-container">
                    <a href="#">
                        <img class="emthemesModez-banner-image" src="https://cdn11.bigcommerce.com/s-jda6rmgufj/product_images/uploaded_images/banner-shop.jpg?t=1561446192&amp;_ga=2.259528074.1810306890.1561366545-1703059024.1550805396" alt="banner shop" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
`}</code></pre>
    <p><img alt="banner content html source editor" src={require("../img/edit-home1-fullwidth-banner.png")} /></p>
    <p>{`Then click `}<strong parentName="p">{`Update`}</strong>{` button to go back the previous screen.`}</p>
    <p>{`Choose `}<strong parentName="p">{`Show on Page`}</strong>{` = `}<strong parentName="p">{`Home Page`}</strong>{`.`}</p>
    <p>{`Choose `}<strong parentName="p">{`Location`}</strong>{` = `}<strong parentName="p">{`Top of Page`}</strong>{`.`}</p>
    <p><img alt="banner content html source editor" src={require("../img/edit-banner-show-on-page-location.png")} /></p>
    <p>{`Click `}<strong parentName="p">{`Save`}</strong>{` button to finish.`}</p>
    <p>{`You can freely edit the HTML code above to change banner images or links but keep the HTML attribute `}<inlineCode parentName="p">{`id="emthemesModezBannersBlockHome02"`}</inlineCode>{` unchanged.`}</p>
    <h2 {...{
      "id": "products-by-categories"
    }}>{`Products By Categories`}</h2>
    <p><img alt="banner content html source editor" src={require("../img/wholesale-product-categories.png")} /></p>
    <p>{`This section will show products organized categories. You can choose number of categories to display products. Categories are sorted by the order specified in `}<strong parentName="p">{`Product Categories`}</strong>{` in admin manager.`}</p>
    <h4 {...{
      "id": "configuration"
    }}>{`CONFIGURATION`}</h4>
    <p>{`To configure this section, go to `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{` > `}<strong parentName="p">{`Products by Category with Sorting Tabs`}</strong>{`:`}</p>
    <p><img alt="banner content html source editor" src={require("../img/theme-editor-products-by-category.png")} /></p>
    <h4 {...{
      "id": "optimize-for-execution-time"
    }}>{`OPTIMIZE FOR EXECUTION TIME`}</h4>
    <p>{`By default, all products will be loaded and displayed when opening the page. At a result, it takes a longer execution time that can affect your site's Page Speed score. To avoid this, you can select `}<strong parentName="p">{`Only load when scrolling to the viewport`}</strong>{` option, the products won't loaded until user scrolls down to this area.`}</p>
    <h4 {...{
      "id": "specify-which-categories-to-display"
    }}>{`SPECIFY WHICH CATEGORIES TO DISPLAY`}</h4>
    <p>{`By default, the product categories displayed are the top categories in the order in which they are sorted on the main navigation. You can limit number of categories to display by setting `}<strong parentName="p">{`Number of Categories`}</strong>{` option.`}</p>
    <p>{`You can manually specify which categories should be displayed by setting the categories ID to Category ID box, separated by comma. For example: `}<inlineCode parentName="p">{`200,221,254,275`}</inlineCode>{`. `}<a parentName="p" {...{
        "href": "https://solidcommerce.zendesk.com/hc/en-us/articles/215722843-Finding-Bigcommerce-Category-Names-and-ID-Numbers#automark4"
      }}>{`Check instruction How to find the category ID`}</a>{`. Note that Number of Categories will be ignored if you choose to display categories manually. `}</p>
    <h4 {...{
      "id": "hot-categories"
    }}>{`HOT CATEGORIES`}</h4>
    <p><strong parentName="p">{`Hot Categories`}</strong>{` appears on the right is the sub-categories of the current category.`}</p>
    <p><img alt="banner content html source editor" src={require("../img/home1-products-by-category-hot-categories.png")} /></p>
    <h4 {...{
      "id": "show-banner-per-category"
    }}>{`SHOW BANNER PER CATEGORY`}</h4>
    <p><img alt="banner content html source editor" src={require("../img/products-by-category-banner.png")} /></p>
    <p>{`To show the banner image per products by category block, tick on option `}<strong parentName="p">{`Show banner`}</strong>{` in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{` > `}<strong parentName="p">{`Products by Category with Sorting Tabs`}</strong>{`:`}</p>
    <p><img alt="banner content html source editor" src={require("../img/theme-editor-show-banner-on-products-by-category.png")} /></p>
    <p>{`Then go to `}<strong parentName="p">{`Storefront`}</strong>{` > `}<strong parentName="p">{`Image Manager`}</strong>{`, upload your banner image with name following this rule: `}<inlineCode parentName="p">{`products-by-category-{i}`}</inlineCode>{` where `}<inlineCode parentName="p">{`{i}`}</inlineCode>{` is replaced by category order starting from 1. The banner image format must be jpg, it means the image on your computer must be `}<inlineCode parentName="p">{`products-by-category-1.jpg`}</inlineCode>{` if it's used for the first category. All letters must be lower case.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      